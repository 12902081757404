import css from "./MerchantSettle.module.css"
import Select from 'react-select';
import {useEffect, useState} from "react";
import UploadOneFile from "../../common/uploadFile/uploadOneFile";
import cssBtn from "../../common/css/Button.module.css"
import {useNavigate} from "react-router-dom";
import {openNoticeInfoPop} from "../../utils/utils";
import ApiUserBusiness from "../../api/apiUserBusiness";
import {selectToken} from "../../store/token";
import {useSelector} from "react-redux";

const MerchantSettle = props => {
    const navigate = useNavigate()
    const token = useSelector(selectToken)
    const [businessType, setBusinessType] = useState({value: '婚庆策划', label: '婚庆策划'})
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [chargeName, setChargeName] = useState("")
    const [chargeMobile, setChargeMobile] = useState("")
    const [businessLicense, setBusinessLicense] = useState("")

    useEffect(()=>{
        if (!token){
            openNoticeInfoPop("请先登录")
            navigate("/login")
        }
    },[token])

    const toHomePage = () => {
        navigate("/home", {replace: true})
    }

    const toCommitCheckIn = () => {
        if (!businessType) {
            openNoticeInfoPop("请选择商家类型")
            return
        }
        if (!companyName) {
            openNoticeInfoPop("请填写商家名")
            return
        }
        if (!companyAddress) {
            openNoticeInfoPop("请填写地址")
            return
        }
        if (!chargeName) {
            openNoticeInfoPop("请填写联系人姓名")
            return
        }
        if (!chargeMobile) {
            openNoticeInfoPop("请填写联系人电话")
            return
        }
        if (!businessLicense) {
            openNoticeInfoPop("请上传营业执照")
            return
        }
        // 更新商家信息
        ApiUserBusiness.updateUserBusinessInfo({
            userId: "",
            companyName, companyType: businessType.value, chargeMobile, businessLicense,
            storeMedias: ["sd"], wxUserQrUrl: "", description: "", labels: [],
            latitude: 0, longitude: 0, address: companyAddress,
            shortAddress: "", province: "", city: "", county: ""
        }).then(data => {
            openNoticeInfoPop("上传成功,等待审核通过")
            toHomePage()
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }

    const businessTypes = [
        {value: '婚庆策划', label: '婚庆策划'},
        {value: '婚纱摄影', label: '婚纱摄影'},
        {value: '司仪主持', label: '司仪主持'},
        {value: '婚宴酒店', label: '婚宴酒店'},
        {value: '婚纱礼服', label: '婚纱礼服'},
        {value: '婚车租赁', label: '婚车租赁'},
    ];

    const selectChange = (value) => {
        setBusinessType(value)
    }

    return <div className={css.outView}>
        <div className={css.titleArea}>
            <div className={css.titleContent}>
                <div className={css.titleText}>亲友悦动商家中心</div>
            </div>
        </div>
        <div className={css.contentArea}>
            <div className={css.checkInText}>商家入驻</div>
            <div className={css.formArea}>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>商家类型</div>
                    <Select className={css.formContentSelect}
                            classNamePrefix={css.formContentSelectPrefix}
                            value={businessType}
                            onChange={selectChange}
                            options={businessTypes}
                    />
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>商家名称</div>
                    <input className={css.formContent} placeholder={"商家名称"} value={companyName} onChange={e => setCompanyName(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>商家地址</div>
                    <input className={css.formContent} placeholder={"商家地址"} value={companyAddress} onChange={e => setCompanyAddress(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>联系人</div>
                    <input className={css.formContent} placeholder={"联系人"} value={chargeName} onChange={e => setChargeName(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>电话号码</div>
                    <input className={css.formContent} placeholder={"电话号码"} value={chargeMobile} onChange={e => setChargeMobile(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>营业执照</div>
                    <div className={css.formContent}>
                        <UploadOneFile defaultUrl={""} onUrlChange={url => {
                            setBusinessLicense(url)
                        }}/>
                    </div>
                </div>
                <div className={css.formButtons}>
                    <div className={cssBtn.redBigBtn} onClick={toCommitCheckIn}>提交</div>
                    <div className={cssBtn.whiteBigBtn} onClick={toHomePage}>取消</div>
                </div>
            </div>
        </div>
    </div>
}
export default MerchantSettle
