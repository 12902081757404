import React from 'react';
import css from "./TbSelected.module.css"

const TbSelected = props => {

    return (
        <div className={css.outView}>
            <div className={css.selected}>
                {props.children}
            </div>
            <div className={css.subLine}/>
        </div>
    );
};


export default TbSelected;
