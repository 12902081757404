import http from "./http"

export default class ApiAction {

    static async userWantPriceRefer(mobile, businessId) {
        return await http.request({
            url: "api/action/user_want_price_refer", method: "post", dataType: "form",
            isNeedToken: false,
            data: {mobile, businessId}
        })
    }

    static async queryUserWantPriceRefList() {
        return await http.request({
            url: "api/action/query_user_want_price_ref_list", method: "get", dataType: "query",
            isNeedToken: true,
            data: {}
        })
    }

}
