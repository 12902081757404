import http from "./http"

export default class ApiCaseItemCreate {

    // 创建案例
    // req:{
    //   "title": "",
    //   "medias": [
    //     ""
    //   ],
    //   "price": 0.00,
    //   "stock": 0,
    //   "memo": "",
    //   "itemDetails": [
    //     {
    //       "sort": 0,
    //       "contentType": "",
    //       "content": "",
    //       "memo": ""
    //     }
    //   ],
    //   "labels": [
    //     ""
    //   ]
    // }
    static async createCaseItem(params) {
        return await http.request({
            url: "api/case/item/create_case_item",
            method: "post", dataType: "json",
            isNeedToken: true,
            data: params,
        })
    }

    // 删除我的案例
    static async deleteMyCaseItem(caseItemId) {
        return await http.request({
            url: "api/case/item/delete_my_case_item",
            method: "post", dataType: "form",
            isNeedToken: true,
            data: {caseItemId}
        })
    }

}
