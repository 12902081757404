import React from 'react';
import css from "./CaseItemNode.module.css"
import {useNavigate} from "react-router-dom";

const CaseItemNode = (props) => {
    const navigate = useNavigate()
    const {item} = props;

    const toCaseDetailPage = () => {
        navigate("/home/case?id=" + item.id)
    }

    return (
        <div className={css.outView} onClick={toCaseDetailPage}>
            <img className={css.img} src={JSON.parse(item.medias)[0]} alt={"cov"}/>
            <div className={css.titleGroup}>
                <div className={css.title}>{item.title}</div>
                <div className={css.price}>￥{item.price}</div>
            </div>
        </div>
    );
};

export default CaseItemNode;
