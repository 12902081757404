import React from 'react';
import css from "./CaseDetailView.module.css"

const CaseDetailView = props => {
    const {item} = props;

    const contentType = item.contentType;
    const content = item.content;

    if (contentType === 'text') {
        return <div className={css.outView}>
            <div className={css.textView}>{content}</div>
        </div>
    } else if (contentType === "picture") {
        return <div className={css.outView}>
            <img className={css.imageView} src={content} alt={"content"}/>
        </div>
    } else if (contentType === "video"){
        return <div className={css.outView}>
            <video src={content} autoPlay={false} controls={true}/>
        </div>
    } else {
        return <div>{item.content}</div>
    }
};

export default CaseDetailView;
