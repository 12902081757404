import React from 'react';
import css from "./LogoBar.module.css"
import {useNavigate} from "react-router-dom";

const LogoBar = props => {
    const navigate = useNavigate()

    const toHomePage = () => {
        navigate("/home",{ replace: true })
        window.location.reload()
    }

    let {width, isWhite} = props;

    if (width === undefined || width === null) {
        width = "372px";
    }

    const imgSrc = isWhite ?
        "/static/logoBarWhite.png"
        : "/static/logoBar.png"

    return <div className={css.outView} onClick={toHomePage}>
        <img width={width} src={imgSrc} alt={"imgSrc"}/>
    </div>
};

export default LogoBar;
