import React from 'react';
import css from "./BottomInfoBar.module.css"
import beianIco from "./image/beianicon.png"

// 底部信息栏
const BottomInfoBar = (props) => {
    return (
        <div className={css.outView}>
            <div className={css.contentView}>
                <div className={css.contentFirst}>
                    <div className={css.contentFirstTextAbout}>关于</div>
                    <div className={css.contentFirstTextAboutContent}>亲友不参与，婚礼不完美！新人分享备婚、结婚过程中的美好瞬间，亲友可以看到幸福分享，并向新人送出衷心祝福。</div>
                    <div className={css.contentFirstTextAboutContent}>亲友悦动，提升婚礼参与体验，促进亲情和婚礼完美。</div>
                    <div className={css.contentFirstTextAboutContent}>全国已有345894对新人使用亲友悦动！</div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%"}}>
                        <div style={{paddingRight: "10px"}}>@2022 版权亲友悦动所有</div>
                        <a href="https://beian.miit.gov.cn/" target="_blank">
                            苏ICP备2021052363号-2</a>
                        <div className={css.gonganBei}>
                            <div style={{paddingRight: "4px"}}>
                                <img width={"20px"} height={"20px"} src={beianIco}/>
                            </div>
                            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32030502000422">苏公网安备32030502000422号</a>
                        </div>
                    </div>
                </div>
                <div className={css.contentRight}>
                    <div className={css.contentRightTitle}>徐州手爪网络科技有限公司</div>
                    <div>地址：徐州经济技术开发区金龙湖公馆第1幢2单元709号</div>
                    <div>电话：13190017563</div>
                    <div>
                        <a href="https://shouzhua.lfxs.top">更多...</a>
                    </div>
                </div>
                <div className={css.contentLeft}>
                    <div className={css.contentLeftTitle}>加入亲友悦动</div>
                    <div className={css.contentLeftTitle2}>一站式婚礼服务，省心贴心</div>
                    <img width={"150px"} src="/static/wxPublicNumQrPic.jpg"/>
                </div>
            </div>

        </div>
    );
}

export default BottomInfoBar;
