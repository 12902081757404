import React, {useEffect, useState} from "react";
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import css from "../../component/ViewHomeMiddleCaseGroup/ViewHomeMiddleCaseGroup.module.css";
import CaseItemCard from "../../component/ViewHomeMiddleCaseGroup/CaseItemCard/CaseItemCard";
import PageNumSelector from "../../component/ViewHomeMiddleCaseGroup/common/PageNumSelector";
import {useDispatch, useSelector} from "react-redux";
import {selectCaseItemData, setSearchParam} from "../../../store/caseItem";
import caseItemThunk from "../../../store/caseItemThunk";
import Loading from "../../../common/Loading/Loading";

const Search = (props) => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.caseItem.loading)
    const data = useSelector(selectCaseItemData)

    useEffect(() => {
        setTimeout(() => {
            if (data.data.length === 0) {
                dispatch(caseItemThunk.queryCaseItemForWebPageShow())
            }
        }, 500);
    }, [])

    const setPageNum = num => {
        dispatch(setSearchParam({pageNum: num}))
        dispatch(caseItemThunk.queryCaseItemForWebPageShow())
    }

    const caseItemNodes = data.data.length === 0 ?
        <div>
            空的
        </div>
        :
        <div className={css.caseItemGroup}>
            {data.data.map(node => {
                return <CaseItemCard key={node.id} item={node}/>
            })}
        </div>

    return (
        <div className={css.outView}>
            <div className={css.caseItemContent}>
                {caseItemNodes}
                <PageNumSelector defaultNum={1} totalNum={data.totalPages} onSelected={num => setPageNum(num)}/>
            </div>
        </div>
    );
}
export default Search;
