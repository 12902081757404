import React, {useEffect, useState} from 'react';
import ApiAction from "../../../api/apiAction";
import {openNoticeInfoPop} from "../../../utils/utils";
import css from "./PersonalWantPrice.module.css"
import {useNavigate} from "react-router-dom";

// 我的页面 我的询价列表 信息：商家的名称 商家的地址 商家的电话
const PersonalWantPrice = () => {

    const [data, setData] = useState([])

    useEffect(() => {

        ApiAction.queryUserWantPriceRefList().then(data => {
            setData(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }, [])

    return (
        <div className={css.outView}>
            {data.map(d =>
                <div className={css.wantPriceLineGroup} key={d.id}>
                    <WantPriceItem item={d}/>
                    <div className={css.line}></div>
                </div>
            )}
        </div>
    );
};

const WantPriceItem = props => {
    const {item} = props
    const navigate = useNavigate()

    const toStorePage = () => {
        navigate("/home/store?id=" + item.businessId)
    }

    return <div className={css.wantPriceItemOutView}>
        <div className={css.imgOutView} onClick={toStorePage}>
            <img className={css.img} src={item.userProfile} alt={"prof"}/>
        </div>
        <div className={css.titleGroup}>
            <div className={css.title}  onClick={toStorePage}>{item.businessCompanyName}</div>
            <div className={css.address}>{item.businessCompanyAddress}</div>
            <div className={css.mobile}>
                <img width={"17px"} height={"20px"} src="/static/mobile.png"/>
                <div>{item.businessCompanyMobile}</div>
            </div>
        </div>
        <div className={css.createAtGroup}>
            <div>{item.createAt}</div>
            <div>&nbsp;咨询</div>
        </div>
    </div>
}

export default PersonalWantPrice;
