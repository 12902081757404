import React, {useEffect, useState} from 'react';
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import css from "../Store/Store.module.css";
import CaseItemNode from "./common/CaseItemNode";

const PersonalBusinessCases = props => {
    const [caseItems, setCaseItems] = useState([]);
    const {businessId} = props

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        ApiCaseItem.queryAllCaseListByBusinessIdForWeb(businessId).then(data => {
            setCaseItems(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }

    return (
        <div className={css.caseItemList}>
            <div className={css.caseItemListData}>
                {caseItems.map(item => <div className={css.itemWrap} key={item.id}>
                    <CaseItemNode item={item} onDeleted={loadData}/>
                    <div className={css.line}/>
                </div>)}
            </div>
        </div>
    );
};


export default PersonalBusinessCases;
