// 上传一个文件
import {useRef, useState} from "react";
import {isPictureOrVideo, openNoticeInfoPop} from "../../utils/utils";
import apiFile from "../../api/apiFile";
import css from "./uploadOneFile.module.css"

const UploadOneFile = (props) => {
    const fileRef = useRef()
    const [process, setProcess] = useState(100);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(props.defaultUrl)
    const {onUrlChange, defaultUrl} = props

    const uploadFile = (file) => {
        setLoading(true)
        deleteFile(url, () => {
            const data = new FormData();
            data.append('file', file);  //相当于 input:file 中的name属性
            setLoading(true)
            apiFile.uploadFile(data, {
                onUploadProgress: progressEvent => {
                    let present = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
                    setProcess(present)
                }
            }).then(data => {
                setUrl(data.url)
                onUrlChange(data.url)
                setLoading(false)
            }).catch(err => {
                openNoticeInfoPop(err)
                setLoading(false)
            })
        })
    };

    const selectedFile = e => {
        const files = e.target.files;
        if (files.length !== 1) {
            openNoticeInfoPop("请选择文件")
            return
        }
        const file = files[0];
        const name = file.name
        const size = file.size
        if (isPictureOrVideo(name) !== "picture") {
            openNoticeInfoPop("请选择图片")
            return
        }
        if (size > 1024 * 1024 * 5) {
            openNoticeInfoPop("图片太大")
            return
        }
        uploadFile(file)
    }


    const deleteFile = (url, next = undefined) => {
        if (url && url !== defaultUrl) {
            apiFile.deleteFile(url).then(data => {
                console.log("删除成功 %o", data) // 不要删除
            }).catch(err => {
                console.log("删除失败 %o", err) // 不要删除
            }).finally(() => {
                setUrl("")
                onUrlChange("")
                setProcess(0)
                if (next) next()
            })
        } else {
            setUrl("")
            onUrlChange("")
            setProcess(0)
            if (next) next()
        }
    }

    let displayNode = <div/>
    if (url) {
        displayNode = <div className={css.imgGroup}>
            <img className={css.imgView} src={url} alt={"url"}/>
            <button onClick={() => deleteFile(url)}>删除</button>
        </div>
    } else {
        if (loading) {
            displayNode = <div className={css.processView}>{process}%</div>
        } else {
            displayNode = <div className={css.processView}>+</div>
        }
    }

    let uploadNode = <div/>
    if (!url) {
        uploadNode = <input className={css.uploadFileView} type="file" name='file' ref={fileRef} multiple={false} accept="image/*" onChange={selectedFile}/>
    } else {
        uploadNode = <></>
    }

    return <div className={css.outView}>
        {displayNode}
        {uploadNode}
    </div>
}

export default UploadOneFile;
