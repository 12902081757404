// 案例详情页
import {useEffect, useState} from "react";
import css from "./Case.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import Loading from "../../../common/Loading/Loading";
import cssBtn from "../../../common/css/Button.module.css"
import cssCommon from "../../../common/css/Common.module.css"
import CaseDetailView from "./common/CaseDetailView";
import ZiXunBaoJia from "./common/ZiXunBaoJia";

const Case = props => {

    const [dialogOpen, setDialogOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [data, setData] = useState(null);
    const id = new URLSearchParams(location.search).get('id')

    if (!id) {
        navigate(-1)
    }

    useEffect(() => {
        ApiCaseItem.queryCaseItemWithDetailForWeb(id).then(data => {
            setData(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }, [id])


    if (!data) {
        return <Loading/>
    }

    const toStorePage = () => {
        navigate("/home/store?id=" + data.caseItem.businessId)
    }

    return <div className={css.outView}>
        <div className={css.contentView}>
            <div className={css.coverInfoArea}>
                <img className={css.coverImage} src={JSON.parse(data.caseItem.medias)[0]} alt={"cover"}/>
                <div className={css.coverTextArea}>
                    <div className={css.coverAreaTitle}>{data.caseItem.title}</div>
                    <div className={css.coverAreaPrice}>￥{data.caseItem.price}</div>
                    <div className={css.coverAreaBusinessInfo}>
                        <div className={css.coverAreaBusinessInfoContent}>
                            <div className={css.coverAreaBusinessContentFirst}>
                                <div style={{width: 80, height: 80, flexGrow: 0, flexShrink: 0}} className={cssCommon.toCenter}>
                                    < img className={css.businessProfileView} src={data.userProfile} alt={"profile"}/>
                                </div>
                                <div className={css.coverAreaBusinessContentFirstRight}>
                                    <div className={css.coverAreaBusinessTitleText}  onClick={toStorePage}>{data.businessCompanyName}</div>
                                    <div className={css.coverCompanyMobileGroup}>
                                        <img width={"17px"} height={"17px"} src="/static/mobile.png" alt={"m"}/>
                                        <div>{data.businessCompanyMobile}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingLeft: 10}} className={cssCommon.toLeft}>
                                <div>{data.businessCompanyAddress}</div>
                            </div>
                        </div>
                    </div>
                    <div className={css.btnGroup}>
                        <div className={cssBtn.redBigBtn} onClick={() => setDialogOpen(true)}>咨询报价</div>
                        <div className={cssBtn.whiteBigBtn} onClick={toStorePage}>进店逛逛</div>
                    </div>
                </div>
            </div>
            <div className={css.caseDetailGroup}>
                <div className={css.caseDetailGroupTitle}>图文详情</div>
                <div className={css.line}/>
                {data.caseItemDetails.map(detail => <CaseDetailView key={detail.id} item={detail}/>)}
            </div>
        </div>
        <ZiXunBaoJia isOpen={dialogOpen} setIsOpen={open => setDialogOpen(open)} businessId={data.caseItem.businessId}/>
    </div>

}
export default Case
