import React from 'react';
import css from "./ViewHomeTopAdArea.module.css"
import {useNavigate} from "react-router-dom";

const ViewHomeTopAdArea = () => {

    const toAdDetail = () => {
        window.open(`https://ad.lfxs.top/JinlonghuHouse`)
    }

    return <div className={css.outView} onClick={toAdDetail}>
        <img className={css.img} src="/static/thirdAdOne.gif" alt={"ad"}/>
        <div className={css.adText}>广告</div>
    </div>
};

export default ViewHomeTopAdArea;
