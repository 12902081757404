import React, {useState} from 'react';
import Modal from "react-modal";
import cssModal from "../../../../common/css/Modal.module.css";
import css from "./ZiXunBaoJia.module.css"
import {openNoticeInfoPop} from "../../../../utils/utils";
import ApiAction from "../../../../api/apiAction";

// 咨询报价 咨询弹窗
const ZiXunBaoJia = props => {

    const {isOpen, setIsOpen, businessId} = props
    const [mobile, setMobile] = useState("");

    const doQueryRefer = () => {
        if (!mobile) {
            openNoticeInfoPop("请填写手机号")
            return
        }
        ApiAction.userWantPriceRefer(mobile, businessId).then(data => {
            openNoticeInfoPop("询价请求已发出,请耐心等候")
            setTimeout(() => {
                setIsOpen(false)
            }, 1000)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            overlayClassName={cssModal.overlayClass}
            className={cssModal.contentClass}
            ariaHideApp={false}
        >
            <div className={css.outView}>
                <div className={css.closeIcon} onClick={() => setIsOpen(false)}>X</div>
                <div className={css.baoJiaView}>咨询报价</div>
                <div className={css.tipText}>稍后商家将通过电话与您联系~</div>
                <input className={css.input} placeholder={"输入手机号码"} onChange={e => setMobile(e.target.value)}/>
                <div className={css.btnOut}>
                    <div className={css.btn} onClick={doQueryRefer}>咨询报价</div>
                </div>
            </div>
        </Modal>
    );
};

export default ZiXunBaoJia;
