import http from "./http"

// 管理员登录等api
export default class apiUser {

    // 手机号密码登录
    static async loginByPassword(phone_num, password) {
        return await http.request({
            url: "api/user/login_by_password", method: "post", dataType: "form",
            isNeedToken: false,
            data: {phone_num, password}
        })
    }

    // 验证码快捷登录
    static async loginOrCreateByValidCode(phone_num, valid_code, password = "") {
        return await http.request({
            url: "api/user/login_or_create_by_valid_code", method: "post", dataType: "form",
            isNeedToken: false,
            data: {phone_num, valid_code, password}
        })
    }

    // 获取我的简要信息
    static async getMySampleInfo() {
        return await http.request({
            url: "api/user/get_my_sample_info", method: "get", dataType: "query",
            isNeedToken: true,
            data: {}
        })
    }

}
