// 混播图单页
import React from "react";
import css from "./CaseItemNode.module.css"
import {useNavigate} from "react-router-dom";

const CaseItemNode = props => {

    const navigate = useNavigate()
    const {item, currentIndex, totalCount} = props
    const labels = JSON.parse(item.labels).join(" | ") + " | " + item.title // 标签

    const toCaseDetailPage = () => {
        navigate("/home/case?id=" + item.id)
    }

    let cover = "";
    if (item.mediaObjs.length > 0) {
        cover = item.mediaObjs[0].url;
    } else {
        cover = JSON.parse(item.medias)[0];
    }

    return <div className={css.outView} onClick={toCaseDetailPage}>
        <img className={css.imgView} src={cover} alt={"cover"}/>
        <div className={css.swiperText}>
            <div style={{flexShrink: 0}}>{labels}</div>
            <div style={{width: "100%"}}></div>
            <div style={{flexShrink: 0, paddingRight: "20px"}}>{currentIndex + 1}/{totalCount}</div>
        </div>
    </div>

}

export default CaseItemNode
