import React from 'react';
import css from "./BaseHomePage.module.css"
import ViewHomeTopThreeCase from "../../component/ViewHomeTopThreeCase/ViewHomeTopThreeCase";
import ViewHomeTopFourBusiness from "../../component/ViewHomeTopFourBusiness/ViewHomeTopFourBusiness";
import ViewHomeTopAdArea from "../../component/ViewHomeTopAdArea/ViewHomeTopAdArea";
import 'react-tabs/style/react-tabs.css';
import ViewHomeMiddleCaseGroup from "../../component/ViewHomeMiddleCaseGroup/ViewHomeMiddleCaseGroup";

const BaseHomePage = props => {
    return (
        <div className={css.outView}>
            <div className={css.contentView}>
                <div className={css.contentViewFirstPart}>
                    <ViewHomeTopThreeCase/>
                    <ViewHomeTopFourBusiness/>
                    <ViewHomeTopAdArea/>
                </div>
                <div className={css.contentViewSecondPart}>
                    <ViewHomeMiddleCaseGroup/>
                </div>
            </div>
        </div>
    );
};

export default BaseHomePage;
