import http from "./http"

export default class ApiUserBusiness {

    // 查询知名商家列表 前四个
    static async queryTopFourBusinessForWeb() {
        return await http.request({
            url: "api/user/query_top_four_business",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: {}
        })
    }

    // 查询商家的信息
    static async getBusinessSampleInfoForWeb(businessId) {
        return await http.request({
            url: "api/user/get_business_sample_info_for_web",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: {businessId}
        })
    }

    // 查询商家的信息
    // req:{
    //   "userId": "",
    //   "companyName": "",
    //   "companyType": "",
    //   "chargeMobile": "",
    //   "businessLicense": "",
    //   "storeMedias": [
    //     ""
    //   ],
    //   "wxUserQrUrl": "",
    //   "description": "",
    //   "labels": [
    //     ""
    //   ],
    //   "latitude": 0.00,
    //   "longitude": 0.00,
    //   "address": "",
    //   "shortAddress": "",
    //   "province": "",
    //   "city": "",
    //   "county": ""
    // }
    static async updateUserBusinessInfo(params) {
        return await http.request({
            url: "api/user/update_user_business_info",
            method: "post", dataType: "json",
            isNeedToken: true,
            data: params
        })
    }

}
