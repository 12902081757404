import css from "./LoginLeftBar.module.css"
import LogoBar from "../component/Logo/LogoBar";

const LoginLeftBar = props => {
    return <div className={css.loginLeftBar}>
        <LogoBar width={"60%"} isWhite={true}/>
        <div className={css.descText}>
            <div style={{display: "block"}}>
                <div style={{display: "block"}}>结婚、订婚</div>
                <div style={{display: "block"}}>上亲友悦动就对了！</div>
            </div>
        </div>
        <div className={css.bottomText}>
            徐州手爪网络科技有限公司
        </div>
    </div>
}

export default LoginLeftBar
