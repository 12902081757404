import React from 'react';
import css from "./CaseItemNode.module.css"
import {useNavigate} from "react-router-dom";
import ApiCaseItemCreate from "../../../../api/apiCaseItemCreate";
import {openNoticeInfoPop} from "../../../../utils/utils";

const CaseItemNode = (props) => {
    const navigate = useNavigate()
    const {item, onDeleted} = props;

    const toCaseDetailPage = () => {
        navigate("/home/case?id=" + item.id)
    }

    const deleteThisCaseItem = () => {
        ApiCaseItemCreate.deleteMyCaseItem(item.id).then(data => {
            onDeleted()
            openNoticeInfoPop("删除成功")
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }

    return (
        <div className={css.outView}>
            <img className={css.img} src={JSON.parse(item.medias)[0]} alt={"cov"} onClick={toCaseDetailPage}/>
            <div className={css.titleGroup}>
                <div className={css.title} onClick={toCaseDetailPage}>{item.title}</div>
                <div className={css.price} onClick={toCaseDetailPage}>￥{item.price}</div>
                <div className={css.deleteBtn} onClick={deleteThisCaseItem}>删除</div>
            </div>
        </div>
    );
};

export default CaseItemNode;
