import React, {useEffect, useState} from 'react';
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import Loading from "../../../common/Loading/Loading";
import CaseItemNode from "./CaseItemNode";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import css from "./ViewHomeTopThreeCase.module.css"

const ViewHomeTopThreeCase = props => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [currentIndex,setCurrentIndex] = useState(0)

    useEffect(() => {
        // 加载最热门的三个案例
        setLoading(true)
        ApiCaseItem.queryTopThreeCases().then(data => {
            setData(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const node = function () {
        if (loading) {
            return <Loading/>
        } else if (data.length === 0) {
            return <div>空</div>
        } else {
            return (
                <Swiper spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={(swiper) => {
                            setCurrentIndex(swiper.activeIndex)
                        }}
                        onSwiper={(swiper) => {
                            setCurrentIndex(swiper.activeIndex)
                        }}
                >
                    {data.map(u => <SwiperSlide key={u.id}>
                        <CaseItemNode item={u} currentIndex={currentIndex} totalCount={data.length}/>
                    </SwiperSlide>)}
                </Swiper>
            );
        }
    }()

    return (
        <div className={css.outView}>
            {node}
        </div>
    );
};

export default ViewHomeTopThreeCase;
