import moment from "moment";
import {toast} from 'react-toastify';

export const openNoticeInfoPop = (msgNode) => {
    toast(msgNode)
};

// 把时间转化为字符串
export const trsTime = (time) => {
    if (time === undefined || time === null)
        return undefined;
    return moment(time).format("YYYY-MM-DD HH:mm:ss")
}

// picture video
export const isPictureOrVideo = function (url) {
    if (!url) {
        return "";
    }
    // 文件后缀名
    let bearUrl = url;
    if (url.indexOf("?") === -1) {
        // 没有？
        bearUrl = url;
    } else {
        // 有？取？之前的部分
        bearUrl = url.split("?").shift();
    }
    // bearUrl=https://aa.com/bb/cc.mp4
    // 获取 bearName=cc.mp4
    const bearName = bearUrl.split("/").pop();
    // https://aa.com/bb.mp4?token=34234 => mp4
    const suffix = bearName.split(".").pop().toLowerCase();

    // 图片格式
    let picSufs = [
        "tif", "pjp", "jfif", "pjpeg", "avif", "ico", "tiff", "gif", "svg", "bmp", "png", "xbm", "jxl", "jpeg", "svgz", "jpg", "webp",
        "pcx",
        "tga",
        "exif",
        "fpx",
        "psd",
        "cdr",
        "pcd",
        "dxf",
        "ufo",
        "eps",
        "ai",
        "raw",
        "wmf",
        "apng",
    ];
    // 视频格式
    let videoSufs = [
        "mov",
        "mp4",
        "mkv",
        "3gp",
        "avi",
        "wmv",
        "mpeg",
        "m4v",
        "asf",
        "flv",
        "f4v",
        "rmvb",
        "rm",
        "vob",
        "dat",
    ];
    // 音频格式
    let audioSufs = [
        "mp3",
        "wav",
        "wma",
        "mp2",
        "flac",
        "midi",
        "ra",
        "ape",
        "aac",
        "cda",
        "ac3",
        "vorbis",
        "opus",
    ];
    // 文本格式
    let textSufs = [
        "txt",
        "log",
        "yaml",
        "yml",
        "json",
        "xml",
        "html",
        "toml",
        "htm",
    ];

    // 判断类型
    if (picSufs.indexOf(suffix) !== -1) {
        return "picture";
    }
    if (videoSufs.indexOf(suffix) !== -1) {
        return "video";
    }
    if (audioSufs.indexOf(suffix) !== -1) {
        return "audio";
    }
    if (textSufs.indexOf(suffix) !== -1) {
        return "text";
    }
    return "";
};
