// 登录的函数
import {setLoading, setData} from "./caseItem";
import {openNoticeInfoPop} from "../utils/utils";
import ApiCaseItem from "../api/apiCaseItem";

export default class caseItemThunk {
    // login Trunk
    static queryCaseItemForWebPageShow = () => (dispatch, getState) => {
        dispatch(setLoading(true))
        ApiCaseItem.queryCaseItemForWebPageShow(getState().caseItem.searchParam).then(res => {
            dispatch(setData(res))
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    };

}
