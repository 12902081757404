import React from 'react';
import css from "./TabNotSelected.module.css"

// 未选中的Tab
const TabNotSelected = props => {
    return (
        <div className={css.notSelected}>
            {props.children}
        </div>
    );
};

export default TabNotSelected;
