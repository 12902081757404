import http from "./http"

export default class ApiSms {

    // 发送验证码
    // 类型 1登录 2改密码 3验证手机号 4快捷登录
    static async sendValidCode(phone_num, type) {
        return await http.request({
            url: "api/sms/send_valid_code", method: "post", dataType: "form",
            isNeedToken: false,
            data: {phone_num, type}
        })
    }

}
