import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectToken} from "../../../store/token";
import {isPictureOrVideo, openNoticeInfoPop} from "../../../utils/utils";
import css from "./CreateCase.module.css"
import UploadOneFile from "../../../common/uploadFile/uploadOneFile";
import cssBtn from "../../../common/css/Button.module.css";
import UploadMultiFile from "../../../common/uploadFile/uploadMultiFile";
import ApiCaseItemCreate from "../../../api/apiCaseItemCreate";

const CreateCase = (props) => {
    const navigate = useNavigate()
    const token = useSelector(selectToken)

    const [loading, setLoading] = useState(false)
    const [caseName, setCaseName] = useState("")
    const [casePrice, setCasePrice] = useState("")
    const [caseCover, setCaseCover] = useState("")
    const [caseDescription, setCaseDescription] = useState("")
    const [caseDetailPictures, setCaseDetailPictures] = useState([])

    useEffect(() => {
        if (!token) {
            openNoticeInfoPop("请先登录")
            navigate("/login")
        }
    }, [token])

    const toHomePage = () => {
        navigate("/home", {replace: true})
    }

    const toCreateCase = () => {
        if (!caseName) {
            openNoticeInfoPop("请填写标题")
            return
        }
        if (!casePrice) {
            openNoticeInfoPop("请填写价格")
            return;
        }
        if (!caseCover) {
            openNoticeInfoPop("请上传封面")
            return;
        }
        if (!caseDescription) {
            openNoticeInfoPop("请添加描述")
            return;
        }
        if (caseDetailPictures.length === 0) {
            openNoticeInfoPop("请至上上传一张详细图片")
            return;
        }
        setLoading(true)
        ApiCaseItemCreate.createCaseItem({
            title: caseName, medias: [caseCover], price: casePrice, stock: 100, memo: "", labels: [],
            itemDetails: [{
                sort: -1, contentType: "text", content: caseDescription, memo: ""
            }, ...caseDetailPictures.map(desc => {
                return {
                    sort: 1, contentType: isPictureOrVideo(desc), content: desc, memo: ""
                }
            })]
        }).then(data => {
            openNoticeInfoPop("创建成功")
            navigate(-1)
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            setLoading(false)
        })

    }

    return <div className={css.outView}>
        <div className={css.contentArea}>
            <div className={css.checkInText}>+新商品/案例</div>
            <div className={css.formArea}>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>商品/案例名称</div>
                    <input className={css.formContent} placeholder={"商品/案例名称"} value={caseName} onChange={e => setCaseName(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>价格</div>
                    <input type="number" step="0.01" className={css.formContent} placeholder={"价格"} value={casePrice} onChange={e => setCasePrice(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>封面图</div>
                    <div className={css.formContent}>
                        <UploadOneFile defaultUrl={""} onUrlChange={url => {
                            setCaseCover(url)
                        }}/>
                    </div>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>图文详情</div>
                    <textarea className={css.formContent} placeholder={"图文详情"} value={caseDescription} onChange={e => setCaseDescription(e.target.value)}/>
                </div>
                <div className={css.formGroup}>
                    <div className={css.formLabel}>详细图片</div>
                    <div className={css.formContent}>
                        <UploadMultiFile defaultUrls={[]} onUrlsChange={urls => {
                            setCaseDetailPictures(urls)
                        }}/>
                    </div>
                </div>
                <div className={css.formButtons}>
                    {loading ?
                        <div className={cssBtn.redBigBtn}>稍等...</div>
                        :
                        <div className={cssBtn.redBigBtn} onClick={toCreateCase}>提交</div>}

                    <div className={cssBtn.whiteBigBtn} onClick={toHomePage}>取消</div>
                </div>
            </div>
        </div>
    </div>
};

export default CreateCase;
