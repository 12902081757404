// 登录的函数
import {setLoading, setLoginInfo} from "./token";
import {openNoticeInfoPop} from "../utils/utils";
import apiUser from "../api/apiUser";

export default class tokenThunk {
    // login Trunk
    static userLoginOrCreateByValidCode = (phoneNum, validCode) => (dispatch, getState) => {
        dispatch(setLoading(true))
        apiUser.loginOrCreateByValidCode(phoneNum, validCode).then(res => {
            dispatch(setLoginInfo(res))
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    };

    static userLoginByPassword = (phoneNum, password) => (dispatch, getState) => {
        dispatch(setLoading(true))
        apiUser.loginByPassword(phoneNum, password).then(res => {
            dispatch(setLoginInfo(res))
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    };

}
