import React, {ReactNode, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {nanoid} from 'nanoid'
import {AxiosHeaders} from "axios";
import http from "./http";

// 记录访问记录的组件
const VisitAtGuard: React.FC<{ children: ReactNode }> = ({children}) => {

    const location = useLocation()

    useEffect(() => {

        let deviceId = localStorage.getItem("deviceId")
        if (!deviceId) {
            deviceId = nanoid()
            localStorage.setItem("deviceId", deviceId)
        }
        const customerHeaders: AxiosHeaders = AxiosHeaders.from();
        customerHeaders["deviceId"] = deviceId
        const req = {
            page: "lfxs.top" + location.pathname,
            memo: "亲友纪",
            other: {pathname: location.pathname}
        }
        http.postJson("api/view/create", false, req, customerHeaders)
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }, [location.pathname])
    return children
}

export default VisitAtGuard
