import React, {useState} from 'react';
import css from "./SearchBar.module.css"
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setSearchParam} from "../../../store/caseItem";
import caseItemThunk from "../../../store/caseItemThunk";

const SearchBar = props => {
    const [searchKey, setSearchKey] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const doSearch = () => {
        if (searchKey){
            navigate("/home/search")
            dispatch(setSearchParam({searchKey: searchKey, userType: "", pageNum: 1, pageSize: 9}))
            dispatch(caseItemThunk.queryCaseItemForWebPageShow())
        }else {
            navigate("/home")
        }
    }

    const onInputKeyUp = (e) => {
        // enter 键
        if (e.keyCode === 13) {
            doSearch()
        }
    }

    return <div className={css.outView}>
        <input className={css.inputItem} placeholder="搜索商家或商品" value={searchKey} onChange={e => setSearchKey(e.target.value)} onKeyUp={onInputKeyUp}/>
        <div className={css.searchBtn} onClick={doSearch}>搜索</div>
    </div>
};

export default SearchBar;
