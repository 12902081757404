import css from "./Login.module.css"
import LoginLeftBar from "./LoginLeftBar";
import LoginRightBar from "./LoginRightBar";

const Login = props => {

    return <div className={css.outView}>
        <div className={css.contentView}>
            <div className={css.contentInfo}><LoginLeftBar/></div>
            <div className={css.contentForm}><LoginRightBar/></div>
        </div>
    </div>
}

export default Login
