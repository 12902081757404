import React, {useEffect, useState} from "react";
import css from "./PersonContent.module.css"
import TbSelected from "../../component/ViewHomeMiddleCaseGroup/common/TbSelected";
import TabNotSelected from "../../component/ViewHomeMiddleCaseGroup/common/TabNotSelected";
import PersonalWantPrice from "./PersonalWantPrice";
import PersonalBusinessCases from "./PersonalBusinessCases";
import {useNavigate} from "react-router-dom";

const PersonContent = props => {
    const [selected, setSelected] = useState("我的咨询") // 商品/案例
    const {isBusiness, userId} = props
    const navigate = useNavigate()

    const toCreateCasePage = () => {
        navigate("/home/createCase")
    }

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
    }

    const one = <div onClick={() => setSelected("我的咨询")}>
        <TabNotSelected>我的咨询</TabNotSelected>
    </div>
    const two = <div onClick={() => setSelected("商品/案例")}>
        <TabNotSelected>商品/案例</TabNotSelected>
    </div>

    return isBusiness === true ?
        <div className={css.outView}>
            <div className={css.labsOut}>
                <div className={css.labs}>
                    {selected === '我的咨询' ? <TbSelected>我的咨询</TbSelected> : one}
                    {selected === '商品/案例' ? <TbSelected>商品/案例</TbSelected> : two}
                </div>
                <div className={css.newCaseBtn} onClick={toCreateCasePage}>+新商品/案例</div>
            </div>
            <div className={css.caseItemContent}>
                {selected === '我的咨询' ? <PersonalWantPrice/> : <></>}
                {selected === '商品/案例' ? <PersonalBusinessCases businessId={userId}/> : <></>}
            </div>
        </div>
        :
        <div className={css.outView}>
            <div className={css.labsOut}>
                <div className={css.labs}>
                    <TbSelected>我的咨询</TbSelected>
                </div>
            </div>
            <div className={css.caseItemContent}>
                <PersonalWantPrice/>
            </div>
        </div>
};

export default PersonContent;
