import React, {useEffect, useState} from 'react';
import ApiUserBusiness from "../../../api/apiUserBusiness";
import {openNoticeInfoPop} from "../../../utils/utils";
import Loading from "../../../common/Loading/Loading";
import css from "./ViewHomeTopFourBusiness.module.css"
import {useNavigate} from "react-router-dom";

const ViewHomeTopFourBusiness = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        ApiUserBusiness.queryTopFourBusinessForWeb().then(data => {
            setData(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const UserBusinessNode = function () {
        if (loading) {
            return <Loading/>
        } else if (data.length === 0) {
            return <div>空</div>
        } else {
            return <div className={css.nodeListView}>
                {data.map(u => <BusinessNode key={u.userId} business={u}/>)}
            </div>
        }
    }()

    return <div className={css.outView}>
        <div className={css.title}>人气商家</div>
        {UserBusinessNode}
    </div>

}

const BusinessNode = (props) => {
    const navigate = useNavigate()
    const {business} = props;
    const toStorePage = () => {
        navigate("/home/store?id=" + business.userId)
    }
    return (
        <div className={css.itemOutView} onClick={toStorePage}>
            <img width={"60px"} src={business.userProfile} alt={"userProfile"}/>
            <div className={css.itemTitleAddressGroup}>
                <div className={css.itemTitle}>{business.companyName}</div>
                <div className={css.itemAddress}>{business.address}</div>
            </div>
        </div>
    );
};

export default ViewHomeTopFourBusiness;
