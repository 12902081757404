import React from 'react';
import css from "./Loading.module.css"

const Loading = props => {
    return <div className={css.loadingOutView}>
        <div>loading...</div>
    </div>
};

export default Loading;
