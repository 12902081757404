import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectToken} from "../../../store/token";
import css from "./LoginStatus.module.css"
import apiUser from "../../../api/apiUser";
import Loading from "../../../common/Loading/Loading";
import {useNavigate} from "react-router-dom";

const LoginStatus = () => {
    const token = useSelector(selectToken)
    const navigate = useNavigate()

    const toLoginPage = () => {
        navigate("/login")
    }

    return (
        <div className={css.outView}>
            {token ?
                <LoginUserNickNameProfile/> :
                <div className={css.tipText} onClick={toLoginPage}>登录/注册</div>}
        </div>
    );
};

const LoginUserNickNameProfile = props => {
    const navigate = useNavigate()
    const token = useSelector(selectToken)
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        if (token) {
            apiUser.getMySampleInfo().then(data => {
                setUserInfo(data)
            }).catch(err => {
                console.log(err) // 不要删除
            })
        }
    }, [token])

    const toPersonPage = () => {
        navigate("/home/person")
    }

    const node = userInfo ? <div className={css.userInfoGroup} onClick={toPersonPage}>
        <img className={css.userInfoProfile} src={userInfo.userProfile} alt={"profile"}/>
        <div className={css.userInfoNickName}>{userInfo.userNickName}</div>
    </div> : <Loading/>;

    return <div>{node}</div>
}

export default LoginStatus;
