import axios, {AxiosHeaders} from 'axios'
import qs from 'qs'
import {openNoticeInfoPop} from "../../../utils/utils";
import {store} from "../../../store/store";
import {setLoginInfo} from "../../../store/token";

export default class http {

    static httpClient = axios.create({
        baseURL: 'https://shouzhua.lfxs.top',
        timeout: 50000,
    })

    //  const data = new FormData();
    //  data.append('file', file);  //相当于 input:file 中的name属性
    static async postFile(url: string, data: FormData, header?: AxiosHeaders): Promise<any> {
        return await http.request({
            url, method: "POST", withToken: true, dataType: "multiForm", data, header
        })
    }

    static async postJson(url: string, withToken: boolean, data: any, header?: AxiosHeaders): Promise<any> {
        return await http.request({
            url, method: "POST", withToken, dataType: "json", data, header
        })
    }

    static async request({url, method, withToken, dataType, data, header}: { url: string, method: string, withToken: boolean, dataType: string, data: any, header?: AxiosHeaders }): Promise<any> {
        // 请求参数
        const customerHeaders: AxiosHeaders = header || AxiosHeaders.from();
        let queryParam: object = {};
        let bodyData: object | null | string = null;
        // 是否需要token
        if (withToken) {
            customerHeaders.Authorization = store.getState().login.token || "";
        } else {
            /* empty */
        }
        // 请求类型
        if (dataType === 'json') {
            bodyData = data;
            queryParam = {};
            customerHeaders['Content-Type'] = 'application/json;charset=utf-8';
        } else if (dataType === 'query') {
            bodyData = {};
            queryParam = data;
        } else if (dataType === 'form') {
            bodyData = qs.stringify(data);
            queryParam = {};
            customerHeaders['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        } else if (dataType === 'multiForm') {
            bodyData = data;
            queryParam = {};
            customerHeaders['Content-Type'] = 'multipart/form-data';
        } else {
            return new Promise((_s, r) => {
                r("未知请求类型")
            })
        }

        return new Promise((resolve, reject) => {
                try {
                    this.httpClient.request({
                        url, method, headers: customerHeaders, params: queryParam, data: bodyData
                    }).then(data => {
                            if (data.status !== 200) {
                                reject(data.data)
                            } else {
                                this.handleApiResData(data.data)
                                    .then(data => resolve(data))
                                    .catch(err => reject(err))
                            }
                        }
                    ).catch(err => {
                        openNoticeInfoPop(err.toString())
                        reject(err)
                    })
                } catch (err) {
                    console.error(err)
                    openNoticeInfoPop(err?.toString() || "r内部错误")
                    reject(err)
                }

            }
        )
    }

    // 处理反回数据 2000 4000 4001 4002 4003
    static async handleApiResData(res: any/*{code:0,message:'',data:null}*/): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (res.code) {
                case 2000: // 正常返回
                    resolve(res.data);
                    return
                case 3000: // 限速
                    openNoticeInfoPop("请求过快,请稍后再试")
                    reject(res.message);
                    return;
                case 4000: // 参数格式不对
                    openNoticeInfoPop(res.message)
                    reject(res.message);
                    return;
                case 4002  :// token无效 跳登录页
                    openNoticeInfoPop("登录信息无效")
                    store.dispatch(setLoginInfo({}))
                    reject(res.message);
                    return;
                case 4001  :// token无效 跳登录页
                    openNoticeInfoPop("登录信息无效")
                    store.dispatch(setLoginInfo({}))
                    reject(res.message);
                    return;
                case 4003:
                    openNoticeInfoPop("无权限")
                    reject(res.message);
                    return;
                case -1:
                    openNoticeInfoPop(res.message)
                    reject(res.message);
                    return;
                default: // 其他错误
                    openNoticeInfoPop("内部错误")
                    console.error(res)
                    reject(res.message);
                    return;
            }
        })
    }

}
