import React, {useEffect, useState} from 'react';
import css from "./PageNumSelector.module.css"

const PageNumSelector = props => {

    const {defaultNum, totalNum, onSelected} = props
    const [current, setCurrent] = useState(defaultNum)
    const [numList, setNumList] = useState([])

    useEffect(() => {
        if (totalNum === 0) {
            return;
        }
        if (totalNum <= 5) {
            let node = []
            for (let i = 1; i < totalNum+1; i++) {
                node.push(i)
            }
            setNumList(node)
            return;
        }
        let node = []
        if (new Set(numList).has(current)) {
            if (current === numList[0] && current !== 1) {
                node = [current - 1, ...numList.slice(0, numList.length - 1)]
                if (node.length <= 3) {
                    node = [node[0] - 1, ...node, node[node.length - 1] + 1]
                }
            } else if (current === numList[numList.length - 1] && current !== totalNum) {
                node = [...numList.slice(1, numList.length), current + 1]
                if (node.length <= 3) {
                    node = [node[0] - 1, ...node, node[node.length - 1] + 1]
                }
            } else {
                return
            }
        } else {
            node = [current, current + 1, current + 2, current + 3, current + 4]
        }
        let node2 = []
        node.forEach(num => {
            if (num <= 0) {
                return;
            } else if (num > totalNum) {
                return
            } else {
                node2.push(num)
            }
        })
        setNumList(node2)
    }, [totalNum, current])

    const onSelectedOne = num => {
        setCurrent(num)
        onSelected(num)
    }
    const goToPro = () => {
        if (current > 1) {
            const newCurrent = current - 1;
            setCurrent(newCurrent)
            onSelected(newCurrent)
        }
    }
    const goToNext = () => {
        if (current < totalNum) {
            const newCurrent = current + 1;
            setCurrent(newCurrent)
            onSelected(newCurrent)
        }
    }

    if (totalNum === 0) {
        return <></>
    }

    const nodeList = numList.map(num => <PageNum key={num} isCur={num === current} onClick={() => onSelectedOne(num)}>{num}</PageNum>);

    return (
        <div className={css.outView}>
            <PageNum onClick={goToPro}><img width={"10px"} src="/static/Vector01.png" alt={"<"}/></PageNum>
            <div className={css.numGroup}>
                {nodeList}
            </div>
            <PageNum onClick={goToNext}><img width={"10px"} src="/static/Vector02.png" alt={">"}/></PageNum>
        </div>
    );
};

// 圆形的小图标
const PageNum = props => {
    const {isCur} = props
    if (isCur) {
        return <div className={css.currentItem}>{props.children}</div>
    } else {
        return <div className={css.notCurrentItem} onClick={props.onClick}>{props.children}</div>
    }
}

export default PageNumSelector;
