import appCss from './App.module.css';
import Home from "./pages/Home/Home";
import {Navigate, Route, Routes} from "react-router-dom"
import MerchantSettle from "./pages/Merchantsettle/MerchantSettle";
import Login from "./pages/Login/Login";
import Search from "./pages/Home/Search/Search";
import BaseHomePage from "./pages/Home/BaseHomePage/BaseHomePage";
import Case from "./pages/Home/Case/Case";
import CreateCase from "./pages/Home/CreateCase/CreateCase";
import Person from "./pages/Home/Person/Person";
import Store from "./pages/Home/Store/Store";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisitAtGuard from "./pages/component/VisitAtGuard/VisitAtGuard";

const App = () => (
    <div className={appCss.App}>
        <VisitAtGuard>
            <Routes>
                <Route path="home" element={<Home/>}>
                    <Route path="" element={<BaseHomePage/>}/>
                    <Route path="search" element={<Search/>}/>
                    <Route path="case" element={<Case/>}/>
                    <Route path="createCase" element={<CreateCase/>}/>
                    <Route path="person" element={<Person/>}/>
                    <Route path="store" element={<Store/>}/>
                    <Route path='*' element={<Navigate to=""/>}></Route>
                </Route>
                <Route path="login" element={<Login/>}/>
                <Route path="merchantSettle" element={<MerchantSettle/>}/>
                <Route path='*' element={<Navigate to="home"/>}></Route>
            </Routes>
            <ToastContainer/>
        </VisitAtGuard>
    </div>
)

export default App;
