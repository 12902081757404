import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import css from "./Store.module.css"
import ApiUserBusiness from "../../../api/apiUserBusiness";
import Loading from "../../../common/Loading/Loading";
import CaseItemNode from "./common/CaseItemNode";
import cssBtn from "../../../common/css/Button.module.css";
import ZiXunBaoJia from "../Case/common/ZiXunBaoJia";
import cssCommon from "./../../../common/css/Common.module.css"

// 店铺页
const Store = props => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [caseItems, setCaseItems] = useState(null);
    const [businessInfo, setBusinessInfo] = useState(null);
    const id = new URLSearchParams(location.search).get('id')

    useEffect(() => {
        ApiCaseItem.queryAllCaseListByBusinessIdForWeb(id).then(data => {
            setCaseItems(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
        ApiUserBusiness.getBusinessSampleInfoForWeb(id).then(data => {
            setBusinessInfo(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        })
    }, [])

    if (!businessInfo) {
        return <Loading/>
    }
    if (!caseItems) {
        return <Loading/>
    }

    return (
        <div className={css.outView}>
            <div className={css.content}>
                <div className={css.businessInfoGroup}>
                    <img width={"150px"} height={"150px"} src={businessInfo.userProfile} alt={"pro"}/>
                    <div className={css.businessInfoView}>
                        <div>{businessInfo.companyName}</div>
                        <div>{businessInfo.address}</div>
                        <div className={css.coverCompanyMobileGroup}>
                            <img width={"17px"} height={"17px"} src="/static/mobile.png" alt={"m"}/>
                            <div>{businessInfo.chargeMobile}</div>
                        </div>
                    </div>
                    <div className={cssCommon.toCenter}>
                        <div className={cssBtn.redBigBtn} onClick={() => setDialogOpen(true)}>咨询报价</div>
                    </div>
                </div>
                <div className={css.caseItemList}>
                    <div className={css.caseItemTipText}>商品/案例</div>
                    <div className={css.line}/>
                    <div className={css.caseItemListData}>
                        {caseItems.map(item => <div className={css.itemWrap} key={item.id}>
                            <CaseItemNode item={item}/>
                            <div className={css.line}/>
                        </div>)}
                    </div>
                </div>
            </div>
            <ZiXunBaoJia isOpen={dialogOpen} setIsOpen={open => setDialogOpen(open)} businessId={businessInfo.userId}/>
        </div>
    );
};

export default Store;
