import React from 'react';
import css from "./ViewTopInfoBar.module.css"
import LogoBar from "../Logo/LogoBar";
import SearchBar from "../SearchBar/SearchBar";
import LoginStatus from "../LoginStatus/LoginStatus";
import BusinessCheckStatus from "../BusinessCheckStatus/BusinessCheckStatus";

// 顶部导航栏
const ViewTopInfoBar = props => {
    return <div className={css.outView}>
        <div className={css.contentView}>
            <LogoBar width={"250px"} isWhite={false}/>
            <SearchBar/>
            <LoginStatus/>
            <BusinessCheckStatus/>
        </div>
    </div>
};

export default ViewTopInfoBar;
