import React, {useCallback, useEffect, useState} from 'react';
import css from "./LoginRightBar.module.css"
import cssBtn from "./../../common/css/Button.module.css"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../../store/token";
import {openNoticeInfoPop} from "../../utils/utils";
import ApiSms from "../../api/apiSms";
import tokenThunk from "../../store/tokenThunk";
import http from "../../api/http";

const LoginRightBar = props => {
    const token = useSelector(selectToken)
    const loginLoading = useSelector(state => state.token.loading)
    const [phoneNum, setPhoneNum] = useState("")
    const [validCode, setValidCode] = useState("")
    const [password, setPassword] = useState("")
    const [leftSecond, setLeftSecond] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isPassword, setIsPassword] = useState(false)
    const dispatch = useDispatch()
    const navigation = useNavigate()

    useEffect(() => {
        if (token) {
            navigation("/home")
        }
    }, [token])

    const login = useCallback(() => {
        if (!phoneNum) {
            openNoticeInfoPop("请填写手机号")
            return
        }

        if (isPassword) {
            if (!password) {
                openNoticeInfoPop("请填写密码")
                return
            }
            http.onLoginProcess = false;
            dispatch(tokenThunk.userLoginByPassword(phoneNum, password)) // store dispatch
        } else {
            if (!validCode) {
                openNoticeInfoPop("请填写验证码")
                return
            }
            http.onLoginProcess = false;
            dispatch(tokenThunk.userLoginOrCreateByValidCode(phoneNum, validCode)) // store dispatch
        }
    }, [isPassword, password, phoneNum, validCode])

    const sendLoginOrCreateSms = useCallback(() => {
        setLoading(true)
        if (!phoneNum) {
            openNoticeInfoPop("请填写手机号")
            return
        }
        ApiSms.sendValidCode(phoneNum, 4).then(data => {
            openNoticeInfoPop("短信已发送,请注意查收")
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [isPassword, phoneNum])

    return (
        <div className={css.outView}>
            <div className={css.titleText}>
                <span className={css.TextWelcome}>欢迎来到</span>
                <span className={css.TextName}>亲友悦动</span>
            </div>
            <form className={css.formGroup}>
                <input className={css.formItem} placeholder="手机号" value={phoneNum} onChange={e => setPhoneNum(e.target.value)}/>
                {
                    isPassword ?
                        <input className={css.formItem} type={"password"} placeholder="密码" value={password} onChange={e => setPassword(e.target.value)}/>
                        : <div className={css.validCodeFormItems}>
                            <input className={css.formItem} placeholder="验证码" value={validCode} onChange={e => setValidCode(e.target.value)}/>
                            <div className={leftSecond !== 0 ? cssBtn.whiteMiddleBtn_disable : cssBtn.whiteMiddleBtn} onClick={sendLoginOrCreateSms}>
                                {leftSecond !== 0 ? leftSecond + "s" : "获取验证码"}
                            </div>
                        </div>
                }
                <div className={css.changeLoginTypeText} onClick={() => setIsPassword(!isPassword)}>{isPassword ? "通过验证码登录/注册?" : "通过密码登录?"}</div>
            </form>
            <div style={{width: "100%", marginTop: "30px"}} className={cssBtn.redBigBtn} onClick={login}>{loginLoading ? "稍等..." : "登录/注册"}</div>
        </div>
    );
};

export default LoginRightBar;
