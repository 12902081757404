import http from "./http"

export default class apiFile {

    static async uploadFile(formData, other = {}) {
        return await http.request({
            url: "api/file/upload_file", method: "post", dataType: "multiForm",
            isNeedToken: true,
            data: formData,
            other
        })
    }

    static async deleteFile(publicUrl) {
        return await http.request({
            url: "api/file/delete_file", method: "post", dataType: "form",
            isNeedToken: true,
            data: {publicUrl}
        })
    }

}
