import React, {useEffect, useState} from 'react';
import css from "./Person.module.css"
import PersonContent from "./PersonContent";
import apiUser from "../../../api/apiUser";
import {openNoticeInfoPop} from "../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {selectToken, setLoginInfo} from "../../../store/token";
import {useNavigate} from "react-router-dom";

const Person = props => {

    const token = useSelector(selectToken)
    const [data, setData] = useState({})
    const  dispatch = useDispatch();
    const  navigate = useNavigate();

    const logout = () => {
        dispatch(setLoginInfo({}))
    }

    useEffect(()=>{
        if (!token){
            openNoticeInfoPop("请先登录")
            navigate("/login")
        }
    },[token])

    useEffect(() => {
        if (token){
            apiUser.getMySampleInfo().then(data => setData(data)).catch(err => openNoticeInfoPop(err))
        }
    }, [])

    const userNode = data ? <div className={css.personInfoView}>
        <div className={css.profileArea}>
            <img className={css.profileImg} src={data.userProfile} alt={"prof"}/>
        </div>
        <div className={css.nickNameArea}>
            <div>{data.userNickName}</div>
            <div>{data.userDescription}</div>
            <div className={css.logoutBtn} onClick={logout}>退出</div>
        </div>
        <div className={css.personSetting}>
            <div>个人信息设置></div>
        </div>
    </div> : <div></div>

    return (
        <div className={css.outView}>
            <div className={css.contentView}>
                <div className={css.userInfo}>
                    {userNode}
                </div>
                <div className={css.contentArea}>
                    <PersonContent isBusiness={data.isUserBusiness} userId={data.id}/>
                </div>
            </div>
        </div>
    );
};

export default Person;
