// 1.加载时初始化数据
//  .登录
//  .获取token
//  .由token获取初始化数据
import {Outlet} from "react-router-dom";
import BottomInfoBar from "../component/ViewBottomInfoBar/BottomInfoBar";
import ViewTopInfoBar from "../component/ViewTopInfoBar/ViewTopInfoBar";
import css from "./Home.module.css"

const Home = (props) => {
    return <div className={css.Home}>
        <ViewTopInfoBar/>
        <Outlet/>
        <BottomInfoBar/>
    </div>
}
export default Home;
