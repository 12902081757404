import http from "./http"

export default class ApiCaseItem {

    // 获取pv前三的案例信息
    static async queryTopThreeCases() {
        return await http.request({
            url: "api/case/item/query_top_three_cases_for_web",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: {}
        })
    }

    // 查询商品列表用于展示 有分页
    static async queryCaseItemForWebPageShow(param) {
        return await http.request({
            url: "api/case/item/query_case_item_for_web_page_show",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: param,
        })
    }

    // 查询商品详细
    static async queryCaseItemWithDetailForWeb(caseItemId) {
        return await http.request({
            url: "api/case/item/query_case_item_with_detail_for_web",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: {caseItemId},
        })
    }

    // 查询商品详细
    static async queryAllCaseListByBusinessIdForWeb(businessId) {
        return await http.request({
            url: "api/case/item/query_all_case_item_by_business_id_for_web",
            method: "get", dataType: "query",
            isNeedToken: false,
            data: {businessId},
        })
    }
}
