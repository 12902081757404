import React, {useEffect, useState} from 'react';
import css from "./ViewHomeMiddleCaseGroup.module.css"
import TbSelected from "./common/TbSelected";
import TabNotSelected from "./common/TabNotSelected";
import ApiCaseItem from "../../../api/apiCaseItem";
import {openNoticeInfoPop} from "../../../utils/utils";
import PageNumSelector from "./common/PageNumSelector";
import Loading from "../../../common/Loading/Loading";
import CaseItemCard from "./CaseItemCard/CaseItemCard";

const ViewHomeMiddleCaseGroup = props => {
    const [data, setData] = useState({data: []})
    const [loading, setLoading] = useState(false)
    const [pageNum, setPageNum] = useState(1)

    const [selected, setSelected] = useState("婚庆策划")
    const userType = ["婚庆策划", "婚纱摄影", "司仪主持", "婚宴酒店", "婚纱礼服", "婚车租赁"]

    useEffect(() => {
        loadData()
    }, [selected, pageNum])

    const loadData = () => {
        setLoading(true)
        ApiCaseItem.queryCaseItemForWebPageShow({pageNum: pageNum, pageSize: 9, userType: selected}).then(data => {
            setData(data)
        }).catch(err => {
            openNoticeInfoPop(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const caseItemNodes = data.data.length === 0 ?
        <div>
            空的
        </div>
        :
        <div className={css.caseItemGroup}>
            {data.data.map(node => {
                return <CaseItemCard key={node.id} item={node}/>
            })}
        </div>

    return (
        <div className={css.outView}>
            <div className={css.labs}>
                {userType.map(type => <div key={type} onClick={() => setSelected(type)}>
                    {selected === type ?
                        <TbSelected>{type}</TbSelected> :
                        <TabNotSelected>{type}</TabNotSelected>}
                </div>)}
            </div>
            <div className={css.caseItemContent}>
                {caseItemNodes}
                <PageNumSelector defaultNum={1} totalNum={data.totalPages} onSelected={num => setPageNum(num)}/>
            </div>
        </div>
    );
};


export default ViewHomeMiddleCaseGroup;
