// 上传一个文件
import {useCallback, useRef, useState} from "react";
import {isPictureOrVideo, openNoticeInfoPop} from "../../utils/utils";
import apiFile from "../../api/apiFile";
import css from "./uploadMultiFile.module.css"

const UploadMultiFile = (props) => {
    const fileRef = useRef()
    const [process, setProcess] = useState(100);
    const [loading, setLoading] = useState(false);
    const [urls, setUrls] = useState(props.defaultUrls) // 默认复制一份不上报
    const {onUrlsChange, defaultUrls} = props

    const uploadFileSample = async file => {
        return new Promise((r, j) => {
            setLoading(true)
            const data = new FormData();
            data.append('file', file);  //相当于 input:file 中的name属性
            setLoading(true)
            apiFile.uploadFile(data, {
                onUploadProgress: progressEvent => {
                    let present = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
                    setProcess(present)
                }
            }).then(data => {
                r(data.url)
                setLoading(false)
            }).catch(err => {
                openNoticeInfoPop(err)
                setLoading(false)
                j(err)
            })
        })
    }

    const selectedFile =async e => {
        const files = e.target.files;
        if (files.length === 0) {
            openNoticeInfoPop("请选择文件")
            return
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const name = file.name
            const size = file.size
            if (isPictureOrVideo(name) !== "picture") {
                openNoticeInfoPop("请选择图片")
                return
            }
            if (size > 1024 * 1024 * 5) {
                openNoticeInfoPop("图片太大")
                return
            }
        }

        const urlSet = [];
        for (let i = 0; i < files.length; i++) {
            const url = await uploadFileSample(files[i]);
            console.log(url)
            urlSet.push(url)
        }
        //
        console.log(urlSet)
        if (urlSet.length > 0){
            const uniUrls = [...urls, ...urlSet];
            setUrls(uniUrls)
            onUrlsChange(uniUrls)
            setProcess(0)
        }
    }

    const deleteFile = useCallback(url => {
        if (url && !new Set(defaultUrls).has(url)) {
            apiFile.deleteFile(url).then(data => {
                console.log("删除成功 %o", data) // 不要删除
            }).catch(err => {
                console.log("删除失败 %o", err) // 不要删除
            }).finally(() => {
                const cur = new Set(urls);
                cur.delete(url)
                setUrls([...cur])
                onUrlsChange([...cur])
                setProcess(0)
            })
        } else {
            const cur = new Set(urls);
            cur.delete(url)
            setUrls([...cur])
            onUrlsChange([...cur])
            setProcess(0)
        }
    }, [defaultUrls, onUrlsChange, urls])

    let displayNode = <div/>
    if (urls.length > 0) {
        displayNode =
            <div className={css.uploadImgGroupView}>
                {urls.map(url => <div className={css.imgGroup} key={url}>
                    <img className={css.imgView} src={url} alt={"url"}/>
                    <button onClick={() => deleteFile(url)}>删除</button>
                </div>)}
            </div>
    } else {
        if (loading) {
            displayNode = <div className={css.processView}>{process}%</div>
        } else {
            displayNode = <div className={css.processView}>+</div>
        }
    }

    let uploadNode = <input className={css.uploadFileView} type="file" name='file' ref={fileRef} multiple={true} accept="image/*" onChange={selectedFile}/>

    return <div className={css.outView}>
        {displayNode}
        {uploadNode}
    </div>
}

export default UploadMultiFile;
