import axios from 'axios'
import {store} from "../store/store";
import qs from 'qs'
import {setLoginInfo} from "../store/token";

export default class http {

    static onLoginProcess = false

    static httpClient = axios.create({
        baseURL: 'https://api.yuedo.lfxs.top/',
        timeout: 200000,
    })

    static async request({url, method/*get put post*/, isNeedToken/*true false*/, dataType/*json query form*/, data/*{}*/, other = {}}) {
        // 请求参数
        let customerHeaders = {};
        let queryParam = {};
        let bodyData = null;
        // 是否需要token
        if (isNeedToken) {
            if (this.onLoginProcess) {
                return new Promise((s, r) => r("登录中"))
            } else {
                const token = store.getState().token.token;
                if (!token || token === "") {
                    // 跳转登录页
                    this.onLoginProcess = true;
                    return new Promise((s, r) => r("token无效,跳转登录页"))
                }
                customerHeaders.Authorization = token;
            }
        } else {
            customerHeaders.Authorization = store.getState().token.token;
        }
        // 请求类型
        if (dataType === 'json') {
            bodyData = data;
            queryParam = {};
            customerHeaders['Content-Type'] = 'application/json;charset=utf-8';
        } else if (dataType === 'query') {
            bodyData = {};
            queryParam = data;
        } else if (dataType === 'form') {
            bodyData = qs.stringify(data);
            queryParam = {};
            customerHeaders['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        } else if (dataType === 'multiForm') {
            bodyData = data;
            queryParam = {};
            customerHeaders['Content-Type'] = 'multipart/form-data';
        } else {
            return new Promise((s, r) => {
                r("未知请求类型")
            })
        }
        return new Promise((resolve, reject) => {
                this.httpClient.request({
                    url, method, headers: customerHeaders, params: queryParam, data: bodyData, ...other
                }).then(data => {
                        if (data.status !== 200) {
                            reject(data.data)
                        } else {
                            this.handleApiResData(data.data)
                                .then(data => resolve(data))
                                .catch(err => reject(err))
                        }
                    }
                ).catch(err => {
                    reject(err)
                })
            }
        )
    }

    // 处理反回数据 2000 4000 4001 4002 4003
    static async handleApiResData(res/*{code:0,message:'',data:null}*/) {
        return new Promise((resolve, reject) => {
            if (res.code === 2000) {
                resolve(res.data)
            } else if (res.code === 4001) {
                // token无效 跳登录页
                this.onLoginProcess = true;
                store.dispatch(setLoginInfo({}))
                reject(res.message)
            } else if (res.code === 4002) {
                // 未携带token 跳登录页
                this.onLoginProcess = true;
                store.dispatch(setLoginInfo({}))
                reject(res.message)
            } else if (res.code === 4003) {
                // 无权限
                reject(res.message)
            } else {
                reject(res.message)
            }
        })
    }

}
