import React from 'react';
import css from "./CaseItemCard.module.css"
import {useNavigate} from "react-router-dom";

const CaseItemCard = props => {
    const {item} = props
    const cover = JSON.parse(item.medias)[0]

    const navigate = useNavigate()

    const toCaseDetailPage = () => {
        navigate("/home/case?id=" + item.id)
    }

    const toStorePage = () => {
        navigate("/home/store?id=" + item.businessId)
    }

    return (
        <div className={css.outView}>
            <img className={css.coverView} src={cover} alt={"封面"} onClick={toCaseDetailPage}/>
            <div className={css.titleView} onClick={toCaseDetailPage}>{item.title}</div>
            <div className={css.priceView}>￥{item.price}</div>
            <div className={css.businessInfoView}>
                <div className={css.userInfoProfileView} onClick={toStorePage}>
                    <img className={css.userInfoProfile} src={item.userProfile} alt={"ProF"}/>
                </div>
                <div className={css.companyInfoView}>
                    <div className={css.companyNameText} onClick={toStorePage}>{item.businessCompanyName}</div>
                    <div className={css.companyAddressText}>{item.businessCompanyAddress}</div>
                </div>
            </div>
        </div>
    );
};

export default CaseItemCard;
