import React, {useEffect, useState} from 'react';
import css from "./BusinessCheckStatus.module.css"
import {useSelector} from "react-redux";
import {selectToken, selectUserId} from "../../../store/token";
import {useNavigate} from "react-router-dom";
import apiUser from "../../../api/apiUser";
import ApiUserBusiness from "../../../api/apiUserBusiness";
import {openNoticeInfoPop} from "../../../utils/utils";

const BusinessCheckStatus = () => {
    const token = useSelector(selectToken)
    const userId = useSelector(selectUserId)
    const navigate = useNavigate()
    const [data, setData] = useState({})

    // 用户是不是商家，如果是则显示商家名称 不是显示商家信息无助
    useEffect(() => {
        if (token) {
            apiUser.getMySampleInfo().then(data => {
                // 显示商家的头像 或商家入驻字样
                ApiUserBusiness.getBusinessSampleInfoForWeb(data.id).then(data => {
                    // userId,companyName,chargeMobile,storeMedias,address,userProfile,userNickName
                    setData(data)
                }).catch(err => {
                    openNoticeInfoPop(err)
                })
            }).catch(err => {
                console.log(err) // 不要删除
            })
        }
    }, [token, userId])

    const toSettlePage = () => {
        navigate("/merchantSettle")
    }

    const toPersonPage = () => {
        navigate("/home/person")
    }

    return (
        <div className={css.outView}>
            {
                data.companyName ? <div className={css.tipText} onClick={toPersonPage}>{data.companyName}</div>
                    : <div className={css.tipText} onClick={toSettlePage}>商家入驻</div>
            }
        </div>
    );
};

export default BusinessCheckStatus;
